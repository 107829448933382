<template>
    <div class="website">
        <check-for-missing-integrations :integrations="integrations">
            <landing-page slot="no-integrations"
                          image="/images/integrations/cta-messages/visitors.png"
                          :headline="$t('visitors.onboarding.headline')"
                          :description="$t('visitors.onboarding.description')">
                <hr>

                <integration service="analytics"
                             :border="true"
                             logo="/images/onboarding/google-analytics.svg"
                             :headline="$t('visitors.onboarding.analytics.headline')"
                             :description="$t('visitors.onboarding.analytics.description')"/>
                <hr>
            </landing-page>

            <comparing-line-charts :is-loading="isLoading" class="container-fluid" @hasLoaded="startTourWhenReady()"/>

            <div class="container-fluid">
                <div class="row row-lg-flex info-row">
                    <div class="col-md-6 col-lg-4 col-md-flex">
                        <info-list class="tour-step-2"/>
                    </div>

                    <div class="col-md-6 col-lg-4 col-md-flex">
                        <top-referrals/>
                    </div>

                    <div class="col-md-12 col-lg-4 col-lg-flex">
                        <top-pages class="tour-step-4"/>
                    </div>
                </div>

                <div class="row breakdown-list" v-if="isFreemium">
                    <div class="col-md-12">
                        <breakdown-list class="tour-step-5"/>
                    </div>
                </div>

                <div class="row benchmarks">
                    <div class="col-md-12">
                        <benchmarks class="tour-step-6"/>
                    </div>
                </div>

                <audience/>

                <div class="row technical-details" v-if="false">
                    <div class="col-md-12">
                        <technical-details/>
                    </div>
                </div>

                <div class="row top-cities">
                    <div class="col-md-4">
                        <top-cities class="tour-step-3"/>
                    </div>
                </div>
            </div>
        </check-for-missing-integrations>
    </div>
</template>

<script>
  import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
  import LandingPage from '@/app/onboarding/components/LandingPage'
  import Integration from '@/app/onboarding/components/integrations/Integration'
  import tourMixin from '@/mixins/tours/tourMixin'
  import organization from '@/mixins/customer/organization'

  import { mapActions, mapGetters } from 'vuex'
  import hasIntegration from '@/mixins/integrations/hasIntegration'
  import trafficSourcesMixin from '@/app/traffic-sources/traffic-sources.mixin'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'
  import lazyLoadComponent from "@/utils/lazy-load-component";
  import SkeletonBox from "@/app/shared/components/SkeletonBox";

  const defaultOptions = {
    loading: SkeletonBox,
    loadingData: {
      props: {
        width: `100%`,
        height: `475px`,
      },
    },
  };

  require('intro.js/introjs.css')

  export default {
    mixins: [tourMixin, organization, hasIntegration, trafficSourcesMixin, datepickerMixin],

    computed: {
      ...mapGetters('customer', {
        region: 'includeInternationalTraffic'
      }),
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),
      ...mapGetters('ghost', {
        isDemo: 'isDemo'
      })
    },

    watch: {
      region: {
        handler: 'load',
        immediate: true
      },
	  isDemo: {
	    handler: 'load',
	    immediate: true
	  }
    },

    data () {
      return {
        isLoading: false,
        integrations: ['Google analytics'],
        visible: [],
        yAxisMaxValue: 0,
        firstChartYAxis: 0,
        secondChartYAxis: 0,
        chartAValues: null,
        chartBValues: null,
        compareChartIsActive: false,
        selectedCompareMethod: 'samePeriodLastYear',
        compareMethods: [
          {
            icon: 'fa-fast-backward',
            slug: 'samePeriodLastYear'
          },
          {
            icon: 'fa-backward',
            slug: 'samePeriodLastPeriod'
          }
        ]
      }
    },

    methods: {
      ...mapActions('website', ['fetchAnalytics']),

      async load () {
        if (this.hasIntegration(this.integrations)) {
          this.isLoading = true

          const i = this.customer.integrations
          let integrationId = ""

          i.forEach(integration => {
              if(integration.service.name === 'Facebook ads'){
                  integrationId = integration.id
                  this.$store.commit('facebookAds/setIntegrationId', integrationId)
              }
          })

          this.$store.commit('website/setIntegrationId', integrationId)
          await this.fetchAnalytics()
          this.isLoading = false
        }
      },

      startTourWhenReady () {
        let options = {
          steps: [
            {
              element: '.tour-step-1',
              intro: '<h5>Følg med i besøgstallet</h5><p>Brug denne funktion til at se, hvor mange der besøger din hjemmeside over tid. Og om dine besøg kommer fra Google, Facebook eller andet</p>',
              position: 'top'
            },
            {
              element: '.tour-step-2',
              intro: '<h5>Kend dine besøgende</h5><p>Hvis dine kunder bruger lang tid på din hjemmeside er det godt. Er de der i kort tid, er det mindre godt. Brug denne funktion til at lære dine besøgendes adfærd at kende </p>',
              position: 'top'
            },
            {
              element: '.tour-step-3',
              intro: '<h5>Her bor dine besøgende</h5><p>Med denne funktion kan du se, hvilke byer de besøgende på din hjemmmeside er bosat i</p>',
              position: 'top'
            },
            {
              element: '.tour-step-4',
              intro: '<h5>Dine mest populære sider</h5><p>Brug denne funktion til at se, hvilke sider på din hjemmeside, der er mest besøgt.</p>',
              position: 'top'
            },
            {
              element: '.tour-step-5',
              intro: '<h5>Se din hjemmesides stabilitet</h5><p>Det er vigtigt, at din hjemmeside er stabil. Brug denne funktion til at sikre dig, at din hjemmeside er aktiv og se, hvorvidt den har været nede i løbet af den sidste måned</p>',
              position: 'top'
            },
            {
              element: '.tour-step-6',
              intro: '<h5>Sammenligne dine resultater med branchen</h5><p>Med denne funktion kan du hurtigt vurdere, hvordan din forretning klarer sig ved at sammenligne dine resultater med gennemsnittet for branchen</p>',
              position: 'top'
            }
          ]
        }

        if (this.isOnboarding) {
          options.steps.push({
            element: '.tour-step-integration',
            intro: '<h5>Fortsæt det gode arbejde</h5><p>Du har nu opsat din første integration i OP\'N. Klik på integrationssiden for at opsætte den næste.</p>'
          })
        }

        this.startTour('visitors', options)
      }
    },

    components: {
      TopCities: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/TopCities')
      }),
      TopReferrals: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/TopReferrals')
      }),
      TopPages: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/TopPages')
      }),
      Audience: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/Audience')
      }),
      InfoList: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/InfoList'),
      }),
      Benchmarks: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/Benchmarks'),
      }),
      CheckForMissingIntegrations,
      LandingPage,
      Integration,
      BreakdownList: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/breakdowns/BreakdownList'),
      }),
      TechnicalDetails: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/TechnicalDetails'),
      }),
      ComparingLineCharts: lazyLoadComponent({
        ...defaultOptions,
        componentFactory: () => import( '@/app/website/components/ComparingLineCharts'),
      })
    }
  }
</script>
